/* global SentryDsn, SentryRelease, SentryEnvironment */
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import browserUpdate from 'browser-update';

// const helper = require('../../functions/helper');

browserUpdate({
  required: {
    e: -3, // edge
    f: -3, // firefox
    o: -3, // opera
    s: -3, // safari
    c: -3, // chrome
  },
  insecure: true, // Notify all browser versions with severe security issues.
});

Sentry.init({
  dsn: SentryDsn,
  release: SentryRelease,
  environment: SentryEnvironment,
  integrations: [
    new BrowserTracing(),
  ],
  tracesSampleRate: 0.25,
});

if (document.getElementsByClassName('light-box').length) {
  import('./modules/pbc-light-box-shower')
    .then(({ default: pbcLightBoxShower }) => {
      pbcLightBoxShower.init();
    }).catch(() => 'An error occurred while loading the component pbc-light-box');
}
if (document.querySelectorAll('p:empty').length) {
  const allEmptyPs = document.querySelectorAll('p:empty');
  for (let p = 0; p < allEmptyPs.length; p++) {
    allEmptyPs[p].style.display = 'none';
  }
}
if (document.querySelectorAll('h1, h2, h3, h4').length) {
  import('./modules/anchor')
    .then(({ default: anchor }) => {
      anchor.init();
    }).catch(() => 'An error occurred while loading the component anchor');
}
if (document.getElementsByClassName('clickable').length) {
  import('./modules/clickable')
    .then(({ default: clickable }) => {
      clickable.init();
    }).catch(() => 'An error occurred while loading the component clickable');
}
if (document.getElementsByClassName('main-nav').length) {
  import('./modules/navigation')
    .then(({ default: navigation }) => {
      navigation.init();
    }).catch(() => 'An error occurred while loading the component navigation');
}
if (document.getElementsByClassName('post-content').length) {
  import('./modules/post-content')
    .then(({ default: postContent }) => {
      postContent.init();
    }).catch(() => 'An error occurred while loading the component postContent');
}
if (document.getElementsByClassName('responsive-image').length) {
  import('./modules/responsive-images')
    .then(({ default: responsiveImages }) => {
      responsiveImages.init();
    }).catch(() => 'An error occurred while loading the component responsiveImages');
}
if (document.getElementsByClassName('photo-rotator').length) {
  import('./modules/photo-rotator')
    .then(({ default: photoRotator }) => {
      photoRotator.init();
    }).catch(() => 'An error occurred while loading the component photoRotator');
}
if (document.getElementsByClassName('pbc-image-slider').length) {
  import('./modules/image-rotator')
    .then(({ default: imageRotator }) => {
      imageRotator.init();
    }).catch(() => 'An error occurred while loading the component imageRotator');
}

if (document.getElementById('pbc-search-bar')) {
  import('./modules/search-bar');
}
// site search bar
if (document.getElementById('pbc-site-search-bar')) {
  import('./modules/SiteSearchBar/SiteSearchBar');
}

if (document.getElementsByClassName('search-container').length) {
  import('./modules/search')
    .then(({ default: search }) => {
      search.init();
    }).catch(() => 'An error occurred while loading the component search');
}
if (document.getElementsByClassName('splide').length) {
  import('./modules/carousel')
    .then(({ default: carousel }) => {
      carousel.init();
    }).catch(() => 'An error occurred while loading the component carousel');
}
if (document.getElementsByClassName('carousel').length) {
  import('./modules/bs-carousel')
    .then(({ default: bsCarousel }) => {
      bsCarousel.init();
    }).catch(() => 'An error occurred while loading the component carousel');
}
if (document.getElementsByClassName('image-switch').length) {
  import('./modules/image-switch')
    .then(({ default: imageSwitch }) => {
      imageSwitch.init();
    }).catch(() => 'An error occurred while loading the component imageSwitch');
}
if (document.getElementsByClassName('form').length) {
  import('./modules/form')
    .then(({ default: form }) => {
      form.init();
    }).catch(() => 'An error occurred while loading the component form');
}
if (document.getElementsByClassName('peg').length) {
  import('./modules/peg')
    .then(({ default: Peg }) => {
      Peg.init();
    }).catch(() => 'An error occurred while loading the component Peg');
}
if (document.getElementsByClassName('masthead-main-container').length) {
  import('./modules/menu-iframe-loader')
    .then(({ default: menu_iframe_loader }) => {
      menu_iframe_loader.init();
    }).catch(() => 'An error occurred while loading the component menu_iframe_loader');
}
if (document.getElementById('tv_listings')) {
  import('./modules/tv-listings');
}
if (document.getElementById('search_parent')) {
  import('./modules/datalist-searchV2');
}
if (document.getElementsByClassName('tab-content').length) {
  import('bootstrap/js/src/tab');
}
if (document.getElementById('billing_application')) {
  import('./modules/billing-application');
}

if (document.getElementById('post-notes')) {
  import('./modules/post-notes');
}
if (document.querySelector('#v-internal-services-signup-form')) {
  import('./modules/services-internal-signup-form');
}
if (document.querySelector('#v-services-signup-form')) {
  import('./modules/services-signup-form');
}
if (document.getElementsByClassName('v-accordion')) {
  import('./modules/accordion');
}
if (document.getElementsByClassName('splide')) {
  import('./modules/carousel');
}
if (document.getElementsByClassName('v-tooltip')) {
  import('./modules/tooltip');
}
if (document.getElementById('map_parent')) {
  import('./modules/datalist-mapV2')
    .then(({ default: mapShapes }) => {
      mapShapes.init();
    }).catch(() => 'An error occurred while loading the component mapShapes');
}

if (document.querySelector('#goddessForm')) {
  import('./modules/goddess');
}
if (document.querySelector('#csAddressForm')) {
  import('./modules/customer_service_address_search');
}

if (document.querySelectorAll('[data-equalheight]').length > 0) {
  import('./modules/equal-height')
    .then(({ default: equalHeight }) => {
      equalHeight.init();
    }).catch(() => 'An error occurred while loading the component equalHeight');
}

if (document.querySelectorAll('[data-equalwidth]').length > 0) {
  import('./modules/equal-width')
    .then(({ default: equalWidth }) => {
      equalWidth.init();
    }).catch(() => 'An error occurred while loading the component equalWidth');
}
if (document.querySelectorAll('[data-square]').length > 0) {
  import('./modules/square')
    .then(({ default: square }) => {
      square.init();
    }).catch(() => 'An error occurred while loading the component "square"');
}

if (document.getElementById('v-home-page-content')) {
  import('./modules/homePage');
}
if (document.getElementById('visual-link')) {
  import('./modules/visual-link');
}

if (document.getElementById('bug-form')) {
  import('./modules/bug-frontend');
}
if (document.getElementById('signup-frontpage')) {
  import('./modules/front-page-services-signup');
}

if (document.getElementsByClassName('v-weather')) {
  import('./modules/weather');
}

if (document.querySelectorAll('.expansion_area').length > 0) {
  import('./modules/expansion-area');
}
if (document.querySelectorAll('.broadband-label').length > 0) {
  import('./modules/vue-broadband-label');
}

if (document.getElementById('v-expansion-form')) {
  import('./modules/expansion-form');
}

setTimeout(() => {
  if (document.getElementById('ssf_address')) {
    import('./functions/functions')
      .then(({ default: functions }) => {
        functions.getAllLinks();
      });
  }
  // giving plenty of time for the page to load.
}, 3000);
